import { AuthorizedLayout } from 'src/components/Layouts';
import Head from 'next/head';
import Discovery from 'src/modules/Discovery';

function SearchPage() {
  return (
    <>
      <Head>
        <title>Discovery | MedScout</title>
      </Head>

      <Discovery />
    </>
  );
}

SearchPage.layout = AuthorizedLayout;

export default SearchPage;
